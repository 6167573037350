@import url("https://fonts.googleapis.com/css2?family=Figtree:wght@300;400;700&display=swap");
:root {
  --maxWidth: 1200px;
  --contentWidth: min(90%, var(--maxWidth));
  --dialogWidth: min(90%, 750px);
  --borderRadius: 6px;
  --headerHeight: 6rem;
  --sizeBase: 16px;
  --xsmall: calc(var(--sizeBase) / 2);
  --small: calc(var(--sizeBase));
  --medium: calc(var(--sizeBase) * 2);
  --large: calc(var(--sizeBase) * 4);
  --xlarge: calc(var(--sizeBase) * 8);
}

:root {
  --box-shadow: 0px 9px 27px -5px hsla(220, 30%, 20%, 0.35), 0px 5px 16px -8px hsla(220, 30%, 20%, 0.6);
  --box-shadow-highlight: 0px 9px 27px -5px hsla(220, 30%, 20%, 0.15), 0px 5px 16px -8px hsla(220, 30%, 20%, 0.3);
  --colour-background-h: 216deg;
  --colour-background-s: 12%;
  --colour-background-l: 92%;
  --colour-background-hsl: 216deg 12% 92%;
  --colour-background-light-hsl: 216deg 12% 94%;
  --colour-background-lighter-hsl: 216deg 12% 96%;
  --colour-background-lightest-hsl: 216deg 12% 98%;
  --colour-background-dark-hsl: 216deg 12% 86%;
  --colour-background-darker-hsl: 216deg 12% 80%;
  --colour-background-darkest-hsl: 216deg 12% 73%;
  --colour-font-h: 221deg;
  --colour-font-s: 16%;
  --colour-font-l: 39%;
  --colour-font-hsl: 221deg 16% 39%;
  --colour-font-light-hsl: 221deg 16% 54%;
  --colour-font-lighter-hsl: 221deg 16% 70%;
  --colour-font-lightest-hsl: 221deg 16% 85%;
  --colour-font-dark-hsl: 221deg 16% 29%;
  --colour-font-darker-hsl: 221deg 16% 20%;
  --colour-font-darkest-hsl: 221deg 16% 10%;
  --colour-feature-h: 332deg;
  --colour-feature-s: 55%;
  --colour-feature-l: 37%;
  --colour-feature-hsl: 332deg 55% 37%;
  --colour-feature-light-hsl: 332deg 55% 53%;
  --colour-feature-lighter-hsl: 332deg 55% 69%;
  --colour-feature-lightest-hsl: 332deg 55% 84%;
  --colour-feature-dark-hsl: 332deg 55% 28%;
  --colour-feature-darker-hsl: 332deg 55% 19%;
  --colour-feature-darkest-hsl: 332deg 55% 9%;
  --colour-alt-h: 23deg;
  --colour-alt-s: 65%;
  --colour-alt-l: 46%;
  --colour-alt-hsl: 23deg 65% 46%;
  --colour-alt-light-hsl: 23deg 65% 59%;
  --colour-alt-lighter-hsl: 23deg 65% 73%;
  --colour-alt-lightest-hsl: 23deg 65% 86%;
  --colour-alt-dark-hsl: 23deg 65% 34%;
  --colour-alt-darker-hsl: 23deg 65% 23%;
  --colour-alt-darkest-hsl: 23deg 65% 11%;
  --colour-error-h: 354deg;
  --colour-error-s: 56%;
  --colour-error-l: 49%;
  --colour-error-hsl: 354deg 56% 49%;
  --colour-error-light-hsl: 354deg 56% 61%;
  --colour-error-lighter-hsl: 354deg 56% 74%;
  --colour-error-lightest-hsl: 354deg 56% 87%;
  --colour-error-dark-hsl: 354deg 56% 36%;
  --colour-error-darker-hsl: 354deg 56% 24%;
  --colour-error-darkest-hsl: 354deg 56% 12%;
  --colour-success-h: 164deg;
  --colour-success-s: 100%;
  --colour-success-l: 29%;
  --colour-success-hsl: 164deg 100% 29%;
  --colour-success-light-hsl: 164deg 100% 47%;
  --colour-success-lighter-hsl: 164deg 100% 65%;
  --colour-success-lightest-hsl: 164deg 100% 82%;
  --colour-success-dark-hsl: 164deg 100% 22%;
  --colour-success-darker-hsl: 164deg 100% 15%;
  --colour-success-darkest-hsl: 164deg 100% 7%;
  --colour-info-h: 204deg;
  --colour-info-s: 100%;
  --colour-info-l: 40%;
  --colour-info-hsl: 204deg 100% 40%;
  --colour-info-light-hsl: 204deg 100% 55%;
  --colour-info-lighter-hsl: 204deg 100% 70%;
  --colour-info-lightest-hsl: 204deg 100% 85%;
  --colour-info-dark-hsl: 204deg 100% 30%;
  --colour-info-darker-hsl: 204deg 100% 20%;
  --colour-info-darkest-hsl: 204deg 100% 10%;
  --colour-warn-h: 43deg;
  --colour-warn-s: 100%;
  --colour-warn-l: 50%;
  --colour-warn-hsl: 43deg 100% 50%;
  --colour-warn-light-hsl: 43deg 100% 63%;
  --colour-warn-lighter-hsl: 43deg 100% 75%;
  --colour-warn-lightest-hsl: 43deg 100% 88%;
  --colour-warn-dark-hsl: 43deg 100% 38%;
  --colour-warn-darker-hsl: 43deg 100% 25%;
  --colour-warn-darkest-hsl: 43deg 100% 13%;
  --form-highlight: hsl(var(--colour-alt-hsl));
}

[data-theme=dark] {
  --box-shadow: 0px 26px 27px -5px hsla(245, 30%, 0%, 0.15), 0px 5px 12px -8px hsla(245, 30%, 0%, 0.6);
  --box-shadow-highlight: 0px 26px 27px -5px hsla(245, 30%, 0%, 0.15), 0px 5px 12px -8px hsla(245, 30%, 0%, 0.6),
  	-4px -8px 16px -8px hsla(220, 30%, 80%, 0.15);
  --colour-background-h: 245deg;
  --colour-background-s: 32%;
  --colour-background-l: 7%;
  --colour-background-hsl: 245deg 32% 7%;
  --colour-background-dark-hsl: 245deg 32% 20%;
  --colour-background-darker-hsl: 245deg 32% 32%;
  --colour-background-darkest-hsl: 245deg 32% 45%;
  --colour-background-light-hsl: 245deg 32% 6%;
  --colour-background-lighter-hsl: 245deg 32% 4%;
  --colour-background-lightest-hsl: 245deg 32% 2%;
  --colour-font-h: 220deg;
  --colour-font-s: 13%;
  --colour-font-l: 65%;
  --colour-font-hsl: 220deg 13% 65%;
  --colour-font-dark-hsl: 220deg 13% 74%;
  --colour-font-darker-hsl: 220deg 13% 82%;
  --colour-font-darkest-hsl: 220deg 13% 91%;
  --colour-font-light-hsl: 220deg 13% 49%;
  --colour-font-lighter-hsl: 220deg 13% 32%;
  --colour-font-lightest-hsl: 220deg 13% 16%;
  --colour-feature-h: 332deg;
  --colour-feature-s: 55%;
  --colour-feature-l: 37%;
  --colour-feature-hsl: 332deg 55% 37%;
  --colour-feature-dark-hsl: 332deg 55% 50%;
  --colour-feature-darker-hsl: 332deg 55% 62%;
  --colour-feature-darkest-hsl: 332deg 55% 75%;
  --colour-feature-light-hsl: 332deg 55% 28%;
  --colour-feature-lighter-hsl: 332deg 55% 19%;
  --colour-feature-lightest-hsl: 332deg 55% 9%;
  --colour-alt-h: 23deg;
  --colour-alt-s: 65%;
  --colour-alt-l: 46%;
  --colour-alt-hsl: 23deg 65% 46%;
  --colour-alt-dark-hsl: 23deg 65% 58%;
  --colour-alt-darker-hsl: 23deg 65% 71%;
  --colour-alt-darkest-hsl: 23deg 65% 83%;
  --colour-alt-light-hsl: 23deg 65% 34%;
  --colour-alt-lighter-hsl: 23deg 65% 23%;
  --colour-alt-lightest-hsl: 23deg 65% 11%;
  --colour-error-h: 354deg;
  --colour-error-s: 56%;
  --colour-error-l: 49%;
  --colour-error-hsl: 354deg 56% 49%;
  --colour-error-dark-hsl: 354deg 56% 61%;
  --colour-error-darker-hsl: 354deg 56% 74%;
  --colour-error-darkest-hsl: 354deg 56% 86%;
  --colour-error-light-hsl: 354deg 56% 36%;
  --colour-error-lighter-hsl: 354deg 56% 24%;
  --colour-error-lightest-hsl: 354deg 56% 12%;
  --colour-success-h: 164deg;
  --colour-success-s: 100%;
  --colour-success-l: 29%;
  --colour-success-hsl: 164deg 100% 29%;
  --colour-success-dark-hsl: 164deg 100% 42%;
  --colour-success-darker-hsl: 164deg 100% 54%;
  --colour-success-darkest-hsl: 164deg 100% 67%;
  --colour-success-light-hsl: 164deg 100% 22%;
  --colour-success-lighter-hsl: 164deg 100% 15%;
  --colour-success-lightest-hsl: 164deg 100% 7%;
  --colour-info-h: 204deg;
  --colour-info-s: 100%;
  --colour-info-l: 40%;
  --colour-info-hsl: 204deg 100% 40%;
  --colour-info-dark-hsl: 204deg 100% 53%;
  --colour-info-darker-hsl: 204deg 100% 65%;
  --colour-info-darkest-hsl: 204deg 100% 78%;
  --colour-info-light-hsl: 204deg 100% 30%;
  --colour-info-lighter-hsl: 204deg 100% 20%;
  --colour-info-lightest-hsl: 204deg 100% 10%;
  --colour-warn-h: 44deg;
  --colour-warn-s: 100%;
  --colour-warn-l: 50%;
  --colour-warn-hsl: 44deg 100% 50%;
  --colour-warn-dark-hsl: 44deg 100% 63%;
  --colour-warn-darker-hsl: 44deg 100% 75%;
  --colour-warn-darkest-hsl: 44deg 100% 88%;
  --colour-warn-light-hsl: 44deg 100% 38%;
  --colour-warn-lighter-hsl: 44deg 100% 25%;
  --colour-warn-lightest-hsl: 44deg 100% 13%;
  --form-highlight: hsl(var(--colour-background-darkest-hsl));
}

:root {
  --fontFamily: "Figtree", sans-serif;
  --fontSize-standard: 1rem;
  --fontSize-xlarge: clamp(2rem, 3.75vmin, 5rem);
  --fontSize-large: clamp(1.25rem, 6cqw, 2rem);
  --fontSize-medium: clamp(1rem, 1.5vmin, 2.5rem);
  --fontSize-small: 0.8rem;
}

.p-checkbox .p-checkbox-box {
  border: 1px solid hsl(var(--colour-font-hsl));
  background: hsl(var(--colour-background-dark-hsl));
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 16px;
  color: hsl(var(--colour-font-hsl));
  border-radius: 3px;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

:is(.p-multiselect-panel, .p-dropdown-panel) .p-checkbox .p-checkbox-box {
  width: 1.25rem;
  height: 1.25rem;
  border-width: 2px;
}

.p-checkbox .p-checkbox-box .p-checkbox-icon {
  transition-duration: 0.2s;
  color: #ffffff;
  font-size: 14px;
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: hsl(var(--colour-feature-hsl));
  background: hsl(var(--colour-feature-hsl));
}

.p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover {
  border-color: var(--colour-warn-hsl);
  background: var(--colour-warn-hsl);
  color: #ffffff;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: hsl(var(--colour-font-hsl));
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  outline: 0 none;
  outline-offset: 0;
  border-color: hsl(var(--colour-font-hsl));
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: hsl(var(--colour-font-hsl));
  background: hsl(var(--colour-font-hsl));
  color: #ffffff;
}

.p-checkbox:is(.p-checkbox-disabled) {
  cursor: not-allowed;
}
.p-checkbox:is(.p-checkbox-disabled) .p-checkbox-box {
  border-color: hsl(var(--colour-font-h), var(--colour-font-s), calc((100% - var(--colour-font-l)) * 0.66 + var(--colour-font-l)));
}

.p-checkbox.p-invalid > .p-checkbox-box {
  border-color: hsl(var(--colour-error-hsl));
}

.p-checkbox.success .p-checkbox-box.p-highlight {
  border-color: hsl(var(--colour-success-hsl));
  background-color: hsl(var(--colour-success-hsl));
}

.p-checkbox.error .p-checkbox-box.p-highlight {
  border-color: hsl(var(--colour-error-hsl));
  background-color: hsl(var(--colour-error-hsl));
}

.p-datatable-auto-layout > .p-datatable-wrfonter {
  overflow-y: visible;
  z-index: 2;
}
.p-datatable-auto-layout > .p-datatable-wrfonter table {
  height: 1px;
}

.p-datatable-header {
  display: none;
}

.p-datatable-thead {
  border-bottom: 1px solid hsl(var(--colour-font-light-hsl));
  text-align: left;
}
.p-datatable-thead th {
  padding: 0px var(--small);
  font-size: var(--fontSize-small);
  text-align: left !important;
  color: hsl(var(--colour-font-light-hsl));
}
.p-datatable-thead th input,
.p-datatable-thead th .p-dropdown-label,
.p-datatable-thead th .p-multiselect-label {
  font-size: var(--fontSize-small);
  color: hsl(var(--colour-font-hsl));
}
.p-datatable-thead tr:first-of-type th {
  text-transform: uppercase;
}
.p-datatable-thead tr:last-of-type th {
  padding: var(--xsmall) var(--small) var(--small) var(--small);
}
.p-datatable-thead .p-column-header-content {
  flex-wrap: wrap;
}
.p-datatable-thead .p-column-header-content > span:first-of-type {
  flex: 1;
}
.p-datatable-thead .p-sortable-column {
  vertical-align: bottom;
}
.p-datatable-thead .p-column-header-content {
  display: grid;
  height: 100%;
  grid-template-rows: 1fr auto;
  grid-template-columns: auto auto 1fr;
  grid-template-areas: "title title title" "sortOrder sort filter";
  row-gap: var(--xsmall);
}
.p-datatable-thead .p-column-header-content .p-column-title {
  grid-area: title;
  align-self: end;
}
.p-datatable-thead .p-column-header-content .p-sortable-column-icon {
  grid-area: sort;
}
.p-datatable-thead .p-column-header-content .p-column-filter {
  grid-area: filter;
}
.p-datatable-thead .p-sortable-column.p-highlight {
  color: hsl(var(--colour-feature-hsl));
}
.p-datatable-thead .p-sortable-column-badge {
  display: flex;
  align-items: center;
  height: var(--fontSize-small);
  width: var(--fontSize-small);
  background-color: hsl(var(--colour-feature-hsl));
  border-radius: 50%;
  font-size: 0.5rem;
  font-weight: 400;
  color: white;
}

.p-datatable-tbody tr {
  border-bottom: 1px solid hsl(var(--colour-font-lightest-hsl));
  line-height: 1.1;
  transition: background-color 150ms ease, color 150ms ease;
}
.p-datatable-tbody tr:hover {
  background-color: hsl(var(--colour-background-dark-hsl));
}
.p-datatable-tbody tr.p-highlight {
  position: relative;
  background-color: hsl(var(--colour-background-dark-hsl));
  color: hsl(var(--colour-feature-hsl));
}
.p-datatable-tbody tr.p-highlight::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 6px;
  background-color: hsl(var(--colour-feature-hsl));
}
.p-datatable-tbody td {
  padding: var(--small);
}
.p-datatable-tbody td span {
  display: flex;
  align-items: center;
}
.p-datatable-tbody td img {
  height: 1.25rem;
}

.p-filter-column input {
  width: min(16ch, 100%);
}

.p-filter-column.customDynamicFilter input {
  width: min(24ch, 100%);
}

.p-filter-column.timestampFilter :is(span, .p-calendar) {
  display: block;
}
.p-filter-column.timestampFilter :is(span, .p-calendar) :is(input, .p-inputtext) {
  width: min(36ch, 100%);
  cursor: pointer;
}

.p-paginator {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  padding: 16px 16px;
  border-top: 1px solid hsl(var(--colour-font-hsl));
  color: hsl(var(--colour-font-light-hsl));
}

.p-paginator-pages {
  display: flex;
  gap: 8px;
  margin: 0 16px;
}

.p-paginator-page {
  color: hsl(var(--colour-font-hsl));
}
.p-paginator-page.p-highlight {
  color: hsl(var(--colour-feature-hsl));
}

.p-paginator-current {
  margin-left: auto;
  line-height: 1.4;
}

.hideExpander .p-row-toggler {
  display: none;
}

.p-row-expanded td {
  padding-left: 0;
  padding-bottom: 0;
}

.datatableItem {
  align-items: center;
  width: max-content;
  padding: var(--xsmall) var(--small);
  border-radius: calc(var(--xsmall) + var(--fontSize-small));
  color: hsl(var(--colour-background-hsl));
}
.datatableItem.success {
  background-color: hsl(var(--colour-success-hsl));
}
.datatableItem.error {
  background-color: hsl(var(--colour-error-hsl));
}
.datatableItem.info {
  background-color: hsl(var(--colour-info-hsl));
}
.datatableItem.warn {
  background-color: hsl(var(--colour-warn-hsl));
}

.p-datepicker {
  max-width: 600px;
  margin-top: 2px;
  padding: 16px;
  background: #ffffff;
  color: hsl(var(--colour-font-hsl));
  border-radius: var(--borderRadius);
  font-size: 0.75rem;
}
.p-datepicker.columnFilter {
  font-size: 0.75rem;
}

.p-datepicker:not(.p-datepicker-inline) {
  background: #ffffff;
  border: 0 none;
  box-shadow: var(--box-shadow);
}
.p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
  background: #ffffff;
}

.p-datepicker .p-datepicker-header {
  padding: 8px;
  background: #ffffff;
  border-bottom: 1px solid hsl(var(--colour-font-h), var(--colour-font-s), calc((100% - var(--colour-font-l)) * 0.75 + var(--colour-font-l)));
  font-weight: 700;
}
.p-datepicker .p-datepicker-header :is(.p-datepicker-prev, .p-datepicker-next) {
  width: 2rem;
  height: 2rem;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 350ms, color 350ms;
}
.p-datepicker .p-datepicker-header :is(.p-datepicker-prev, .p-datepicker-next):enabled:hover {
  border-color: transparent;
}
.p-datepicker .p-datepicker-header .p-datepicker-title {
  line-height: 2rem;
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  margin-right: 8px;
}

.p-datepicker .p-datepicker-header .p-datepicker .p-datepicker-header .p-datepicker-title select {
  transition: background-color 350ms, color 350ms, border-color 350ms;
}

.p-datepicker table {
  margin: 0.5rem 0;
}
.p-datepicker table th,
.p-datepicker table td {
  padding: 0.5rem;
}
.p-datepicker table th > span,
.p-datepicker table td > span {
  width: 2.5em;
  height: 2.5em;
  border-radius: 50%;
  transition: background-color 350ms ease;
}
.p-datepicker table td > span.p-highlight {
  background: hsl(var(--colour-feature-h), var(--colour-feature-s), calc((100% - var(--colour-feature-l)) * 0.75 + var(--colour-feature-l)));
}
.p-datepicker table td > span.p-disabled {
  color: hsl(var(--colour-font-h), var(--colour-font-s), calc((100% - var(--colour-font-l)) * 0.66 + var(--colour-font-l)));
}
.p-datepicker table td.p-datepicker-today > span {
  border: 1px solid hsl(var(--colour-font-hsl));
}

.p-datepicker .p-datepicker-buttonbar {
  padding: 1rem 0;
  border-top: 1px solid hsl(var(--colour-font-h), var(--colour-font-s), calc((100% - var(--colour-font-l)) * 0.75 + var(--colour-font-l)));
}

.p-datepicker .p-datepicker-buttonbar .p-button {
  width: auto;
  background-color: #ffffff;
}

.p-datepicker-timeonly .p-timepicker {
  font-size: var(--fontMedium);
}

.p-calendar .p-inputtext:read-only:not(.p-disabled) {
  cursor: pointer;
  background-color: hsl(var(--colour-background-h), var(--colour-background-s), var(--colour-background-l));
  color: hsl(var(--colour-font-hsl));
}

.p-dialog-mask {
  background-color: rgba(0, 0, 0, 0.45);
  backdrop-filter: blur(6px);
}

.p-dialog {
  padding: 32px;
  width: var(--dialogWidth);
  background-color: hsl(var(--colour-background-hsl));
  border: 3px solid hsl(var(--colour-background-dark-hsl));
  border-radius: var(--borderRadius);
  box-shadow: var(--box-shadow);
  color: hsl(var(--colour-font-hsl));
}

.p-dialog-header {
  position: relative;
  display: flex;
  align-items: center;
  font-weight: 700;
  margin-bottom: var(--small);
}
.p-dialog-header::after {
  --height: 6px;
  content: "";
  position: absolute;
  bottom: calc(var(--height) * -2);
  height: var(--height);
  width: 80px;
  background-color: hsl(var(--colour-alt-hsl));
  border-radius: calc(var(--height) / 2);
}

.p-dialog-title {
  font-size: 1.5rem;
}

.p-dialog-footer {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-top: var(--medium);
}

.p-dialog-header-icon {
  color: hsl(var(--colour-feature-hsl));
  transition: color 350ms ease;
}
.p-dialog-header-icon:hover {
  color: hsl(var(--colour-alt-hsl));
}

.p-dialog-content {
  padding: 32px 0px;
}

.p-dialog:not(:has(.p-dialog-footer)) .p-dialog-content {
  padding-bottom: 0;
}

.loadingDialog {
  color: black;
}

.dialogContentMaxHeight {
  max-height: 40vh;
  overflow-y: auto;
}

.p-dropdown,
.p-multiselect {
  display: inline-flex;
  align-items: center;
  height: calc(var(--medium) * 1.5);
  width: auto;
  padding: 0 var(--small);
  background-color: hsl(var(--colour-background-hsl));
  border: 1px solid hsl(var(--colour-font-lighter-hsl));
  border-radius: var(--borderRadius);
  color: hsl(var(--colour-font-hsl));
  transition: border-color 350ms ease;
}
.p-dropdown:hover,
.p-multiselect:hover {
  border-color: hsl(var(--colour-font-hsl));
}
.p-dropdown:focus, .p-dropdown.p-inputwrapper-focus,
.p-multiselect:focus,
.p-multiselect.p-inputwrapper-focus {
  border-color: hsl(var(--colour-background-darker-hsl));
}
.p-dropdown.p-error,
.p-multiselect.p-error {
  border-color: hsl(var(--colour-error-hsl));
  border-right-width: 16px;
}

.p-dropdown-clear-icon,
.p-multiselect-clear-icon {
  position: relative;
  margin: 0;
  top: auto;
  padding-right: 8px;
  order: -1;
}

.p-dropdown-label,
.p-multiselect-label {
  color: hsl(var(--colour-font-hsl));
  font-weight: 400;
}

.p-dropdown-trigger,
.p-multiselect-trigger {
  padding-left: 16px;
  color: hsl(var(--colour-font-hsl));
}

.p-dropdown-panel,
.p-multiselect-panel,
.p-autocomplete-panel {
  margin-top: 2px;
  background-color: hsl(var(--colour-background-dark-hsl));
  border-radius: var(--borderRadius);
  box-shadow: var(--box-shadow);
  overflow: hidden;
}

.p-dropdown-item,
.p-multiselect-item,
.p-autocomplete-item {
  display: flex;
  align-items: center;
  padding: var(--small) var(--small);
  transition: background-color 350ms ease;
  color: hsl(var(--colour-font-hsl));
}
.p-dropdown-item:hover:not(.p-disabled),
.p-multiselect-item:hover:not(.p-disabled),
.p-autocomplete-item:hover:not(.p-disabled) {
  background-color: hsl(var(--colour-background-darker-hsl));
}
.p-dropdown-item img,
.p-multiselect-item img,
.p-autocomplete-item img {
  height: 1.25rem;
}

.p-multiselect-header {
  padding: var(--small) var(--small);
  background-color: hsl(var(--colour-background-darker-hsl));
  border-bottom: 1px solid hsl(var(--colour-background-darkest-hsl));
}

.p-multiselect-close {
  color: hsl(var(--colour-feature-hsl));
  transition: color 350ms ease;
}
.p-multiselect-close:hover {
  color: hsl(var(--colour-alt-hsl));
}

.p-dropdown.p-disabled {
  pointer-events: auto;
  color: hsl(var(--colour-app-h), var(--colour-app-s), calc((100% - var(--colour-app-l)) * 0.66 * var(--emphasis-multiplyer) + var(--colour-app-l)));
}
.p-dropdown.p-disabled:is(:hover, :focus) {
  border-color: hsl(var(--colour-app-h), var(--colour-app-s), calc((100% - var(--colour-app-l)) * 0.66 * var(--emphasis-multiplyer) + var(--colour-app-l)));
}
.p-dropdown.p-disabled .p-dropdown-label,
.p-dropdown.p-disabled .p-dropdown-trigger {
  color: hsl(var(--colour-app-h), var(--colour-app-s), calc((100% - var(--colour-app-l)) * 0.66 * var(--emphasis-multiplyer) + var(--colour-app-l)));
}

.p-dropdown-filter-container,
.p-multiselect-filter-container {
  display: flex;
  margin: var(--xsmall) var(--small);
}
.p-dropdown-filter-container .p-dropdown-filter-icon,
.p-dropdown-filter-container .p-multiselect-filter-icon,
.p-multiselect-filter-container .p-dropdown-filter-icon,
.p-multiselect-filter-container .p-multiselect-filter-icon {
  right: 1rem;
  color: hsl(var(--colour-font-hsl));
}

.p-multiselect-filter-container {
  margin-left: 0;
}

.p-multiselect-label {
  display: flex;
  gap: var(--small);
}

.feature:is(.p-dropdown, .p-multiselect) {
  border-color: hsl(var(--colour-feature-hsl));
  background-color: hsl(var(--colour-feature-hsl));
  box-shadow: var(--box-shadow);
}
.feature:is(.p-dropdown, .p-multiselect) .p-dropdown-label,
.feature:is(.p-dropdown, .p-multiselect) .p-dropdown-trigger {
  color: #fff;
}

.p-fileupload {
  border: 1px solid hsl(var(--colour-font-lighter-hsl));
  border-radius: var(--borderRadius);
}

.p-fileupload-buttonbar {
  display: flex;
  column-gap: var(--small);
  padding: var(--small);
  background-color: hsl(var(--colour-background-darker-hsl));
}

.p-fileupload-content {
  padding: var(--small);
}

.formField .p-inputswitch {
  margin: auto 0;
}

.p-inputswitch {
  width: 3rem;
  height: 1.75rem;
}
.p-inputswitch .p-inputswitch-slider {
  background-color: hsl(var(--colour-font-lighter-hsl));
  transition: background-color 0.2s, color 0.2s;
  border-radius: 30px;
}
.p-inputswitch .p-inputswitch-slider:before {
  background-color: hsl(var(--colour-font-dark-hsl));
  width: 1.25rem;
  height: 1.25rem;
  left: 0.25rem;
  margin-top: -0.625rem;
  border-radius: 50%;
  transition-duration: 0.2s;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background-color: hsl(var(--colour-feature-hsl));
}

[data-theme=dark] .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  --colour-font-lightest-hsl: var(--colour-font-darkest-hsl);
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  background-color: hsl(var(--colour-font-lightest-hsl));
  transform: translateX(1.25rem);
}

.p-inputswitch.p-focus .p-inputswitch-slider {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px hsl(var(--colour-background-darker-hsl));
}

.p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider:before {
  background-color: hsl(var(--colour-font-darkest-hsl));
}

.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
  background: hsl(var(--colour-feature-dark-hsl));
}

.p-skeleton {
  background-color: hsl(var(--colour-background-darker-hsl));
}
.p-skeleton:not(.p-skeleton-circle) {
  border-radius: var(--borderRadius);
}

.p-skeleton:after {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0));
}

.paginatorSkeleton {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  padding: 16px 16px;
  border-top: 1px solid hsl(var(--colour-font-hsl));
}

.p-steps .p-steps-item .p-menuitem-link {
  background: hsl(var(--colour-background-hsl));
  border-radius: 3px;
  transition: box-shadow 0.2s;
}

.p-steps .p-steps-item .p-menuitem-link .p-steps-number {
  z-index: 1;
  height: 2rem;
  width: 2rem;
  background: hsl(var(--colour-background-hsl));
  border: 2px solid hsl(var(--colour-font-hsl));
  border-radius: 50%;
  line-height: 2rem;
  font-size: 1rem;
  font-weight: 700;
  color: hsl(var(--colour-font-hsl));
}

.p-steps .p-steps-item .p-menuitem-link .p-steps-title {
  margin-top: 0.5rem;
  color: hsl(var(--colour-font-hsl));
}

.p-steps .p-steps-item .p-menuitem-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}

.p-steps .p-steps-item.p-highlight .p-steps-number {
  background: hsl(var(--colour-feature-hsl));
  color: hsl(var(--colour-font-lightest-hsl));
}

.p-steps .p-steps-item.p-highlight .p-steps-title {
  font-weight: 600;
  color: hsl(var(--colour-feature-hsl));
}

.p-steps .p-steps-item:before {
  content: " ";
  border-top: 1px solid hsl(var(--colour-font-hsl));
  width: 100%;
  top: 50%;
  left: 0;
  display: block;
  position: absolute;
  margin-top: -1rem;
}

.p-timeline {
  display: flex;
  flex-direction: column;
}
.p-timeline.p-timeline-vertical .p-timeline-event {
  column-gap: var(--small);
}

.p-timeline-event-opposite {
  flex: 0 0 11ch;
  padding: 0;
  padding-bottom: var(--medium);
}

.p-timeline-event-content {
  flex: 1;
  padding: 0;
  padding-bottom: var(--medium);
}

.p-timeline-event-connector {
  flex-grow: 1;
  width: 2px;
  background-color: hsl(var(--colour-background-darker-hsl));
}

.customTimelineMarker {
  display: flex;
  place-items: center;
  place-content: center;
  align-self: baseline;
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  background-color: hsl(var(--colour-feature-hsl));
  color: hsl(var(--colour-background-hsl));
}
.customTimelineMarker i {
  width: max-content;
  font-size: 0.75rem;
}

.p-toast {
  z-index: 999999 !important;
}
.p-toast > div {
  display: flex;
  flex-direction: column;
  gap: var(--small);
}
.p-toast .p-toast-icon-close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  color: inherit;
}
.p-toast .p-toast-message {
  position: relative;
  padding: var(--small) var(--medium) var(--small) var(--small);
  border-left-style: solid;
  border-left-width: var(--xsmall);
  border-radius: var(--borderRadius);
  box-shadow: var(--box-shadow);
}
.p-toast .p-toast-message-content {
  display: flex;
  align-items: center;
  padding: 0;
}
.p-toast .p-toast-message.p-toast-message-success {
  background-color: hsl(var(--colour-success-h), var(--colour-success-s), calc(var(--colour-success-l) * 3.3));
  border-color: hsl(var(--colour-success-hsl));
  color: hsl(var(--colour-success-hsl));
}
.p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close:hover {
  color: hsl(var(--colour-success-hsl));
}
.p-toast .p-toast-message.p-toast-message-error {
  background-color: hsl(var(--colour-error-h), var(--colour-error-s), calc(var(--colour-error-l) * 1.95));
  border-color: hsl(var(--colour-error-hsl));
  color: hsl(var(--colour-error-hsl));
}
.p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close:hover {
  color: hsl(var(--colour-error-hsl));
}
.p-toast .p-toast-message.p-toast-message-info {
  background-color: hsl(var(--colour-info-h), var(--colour-info-s), calc(var(--colour-info-l) * 2.35));
  border-color: hsl(var(--colour-info-hsl));
  color: hsl(var(--colour-info-hsl));
}
.p-toast .p-toast-message.p-toast-message-info .p-toast-icon-close:hover {
  color: hsl(var(--colour-info-hsl));
}
.p-toast .p-toast-message.p-toast-message-warn {
  background-color: hsl(var(--colour-warn-h), var(--colour-warn-s), calc(var(--colour-warn-l) * 1.95));
  border-color: hsl(var(--colour-warn-hsl));
  color: hsl(var(--colour-warn-hsl));
}
.p-toast .p-toast-message.p-toast-message-warn .p-toast-icon-close:hover {
  color: hsl(var(--colour-warn-hsl));
}

.p-toast-message-icon {
  margin-right: var(--small);
  font-size: var(--fontSize-large);
}

.p-toast-summary {
  font-size: var(--fontSize-medium);
  font-weight: 700;
}

.customTooltip {
  cursor: pointer;
}

.p-tooltip {
  --tooltip-background: hsl(var(--colour-background-hsl));
  --tooltip-border: hsl(var(--colour-background-dark-hsl));
  --tooltip-text: hsl(var(--colour-font-hsl));
}
.p-tooltip.header {
  --tooltip-background: hsl(var(--colour-app-hsl));
  --tooltip-text: hsl(var(--colour-font-hsl));
}
.p-tooltip .p-tooltip-text {
  max-width: 25ch;
  background: var(--tooltip-background);
  border: 1px solid var(--tooltip-border);
  color: var(--tooltip-text);
  padding: var(--xsmall);
  box-shadow: var(--box-shadow);
  border-radius: var(--borderRadius);
}
.p-tooltip.p-tooltip-right .p-tooltip-arrow {
  border-right-color: var(--tooltip-border);
}
.p-tooltip.p-tooltip-left .p-tooltip-arrow {
  border-left-color: var(--tooltip-border);
}
.p-tooltip.p-tooltip-top .p-tooltip-arrow {
  border-top-color: var(--tooltip-border);
}
.p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
  border-bottom-color: var(--tooltip-border);
}

.withTooltip {
  position: relative;
  cursor: pointer;
}
.withTooltip::after {
  z-index: 1000;
  content: attr(aria-label);
  position: absolute;
  width: max-content;
  top: 110%;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: var(--xsmall) var(--small);
  background: hsl(var(--colour-font-hsl));
  border-radius: var(--borderRadius);
  color: hsl(var(--colour-background-hsl));
  font-family: var(--font);
  font-size: var(--fontSmall);
  opacity: 0;
  transform: scale(0);
  transform-origin: top;
  transition: transform 0ms ease-in-out 100ms, opacity 100ms linear;
}
.withTooltip:hover::after {
  opacity: 1;
  transform: scale(1);
  transition: transform 100ms ease-in-out, opacity 100ms linear;
}

.appErrorWrapper h1 {
  font-size: clamp(4rem, 9vmin, 6rem);
}
.appErrorWrapper .logo {
  width: 60%;
  margin-inline: auto;
}
.appErrorWrapper .appName span {
  letter-spacing: 0.8rem;
}

button:not(.p-link, .card),
.p-button:not(.p-link, .card),
input[type=submit]:not(.p-link, .card) {
  cursor: pointer;
  position: relative;
  justify-content: center;
  height: calc(var(--medium) * 1.5);
  min-width: max-content;
  column-gap: var(--xsmall);
  padding: 0 var(--medium);
  background-color: hsl(var(--colour-background-hsl));
  border: 2px solid hsl(var(--colour-feature-hsl));
  border-radius: var(--borderRadius);
  color: hsl(var(--colour-feature-hsl));
  font-weight: 700;
  font-size: var(--fontSize-standard);
  transition: color 350ms ease, border-color 350ms ease, background-color 500ms ease, transform 350ms ease;
}
button:not(.p-link, .card) span,
.p-button:not(.p-link, .card) span,
input[type=submit]:not(.p-link, .card) span {
  z-index: 2;
}
button:not(.p-link, .card):hover:not(.p-disabled, :disabled),
.p-button:not(.p-link, .card):hover:not(.p-disabled, :disabled),
input[type=submit]:not(.p-link, .card):hover:not(.p-disabled, :disabled) {
  transform: scale(1.05);
}
button:not(.p-link, .card):active:not(.p-disabled, :disabled),
.p-button:not(.p-link, .card):active:not(.p-disabled, :disabled),
input[type=submit]:not(.p-link, .card):active:not(.p-disabled, :disabled) {
  transform: scale(0.9);
}
button:not(.p-link, .card).success,
.p-button:not(.p-link, .card).success,
input[type=submit]:not(.p-link, .card).success {
  --colour-feature-hsl: var(--colour-success-hsl);
  border-color: hsl(var(--colour-success-hsl));
  color: hsl(var(--colour-success-hsl));
}
button:not(.p-link, .card).error,
.p-button:not(.p-link, .card).error,
input[type=submit]:not(.p-link, .card).error {
  --colour-feature-hsl: var(--colour-error-hsl);
  border-color: hsl(var(--colour-error-hsl));
  color: hsl(var(--colour-error-hsl));
}
button:not(.p-link, .card).feature,
.p-button:not(.p-link, .card).feature,
input[type=submit]:not(.p-link, .card).feature {
  background-color: hsl(var(--colour-feature-hsl));
  box-shadow: var(--box-shadow);
  color: hsl(var(--colour-background-hsl));
}
button:not(.p-link, .card).feature:is(.p-disabled, :disabled),
.p-button:not(.p-link, .card).feature:is(.p-disabled, :disabled),
input[type=submit]:not(.p-link, .card).feature:is(.p-disabled, :disabled) {
  color: hsl(var(--colour-background-darkest-hsl));
}
button:not(.p-link, .card).secondary,
.p-button:not(.p-link, .card).secondary,
input[type=submit]:not(.p-link, .card).secondary {
  border-color: hsl(var(--colour-secondary-hsl));
  color: hsl(var(--colour-secondary-hsl));
}
button:not(.p-link, .card).rounded,
.p-button:not(.p-link, .card).rounded,
input[type=submit]:not(.p-link, .card).rounded {
  width: calc(var(--medium) * 1.5);
  padding: 0;
  border-radius: 50%;
}
button:not(.p-link, .card):is(.p-disabled, :disabled):is(.feature),
.p-button:not(.p-link, .card):is(.p-disabled, :disabled):is(.feature),
input[type=submit]:not(.p-link, .card):is(.p-disabled, :disabled):is(.feature) {
  filter: saturate(40%) brightness(120%);
}
button:not(.p-link, .card):is(.p-disabled, :disabled):not(.feature),
.p-button:not(.p-link, .card):is(.p-disabled, :disabled):not(.feature),
input[type=submit]:not(.p-link, .card):is(.p-disabled, :disabled):not(.feature) {
  border-color: hsl(var(--colour-feature-lighter-hsl));
  color: hsl(var(--colour-feature-lighter-hsl));
  filter: saturate(60%);
}
button:not(.p-link, .card).appNav,
.p-button:not(.p-link, .card).appNav,
input[type=submit]:not(.p-link, .card).appNav {
  background: transparent;
  border: none;
  color: hsl(var(--colour-background-hsl));
  font-size: var(--fontLarge);
}

.p-button-icon-only:not(.rounded) {
  padding: 0 var(--small) !important;
}
.p-button-icon-only .p-button-label {
  display: none;
}

.subtitle .p-button {
  --hover-colour: hsl(var(--colour-app-h) var(--colour-app-s) calc(var(--colour-app-l) * 1.1));
  transform: scale(0);
  border: none;
  color: hsl(var(--colour-font-h), var(--colour-font-s), calc(var(--colour-font-l) * 1.66));
  transition: transform 350ms ease, background-color 350ms ease;
}
.subtitle .p-button.error {
  --hover-colour: hsl(var(--colour-error-h) var(--colour-error-s) calc(var(--colour-error-l) * 1.85));
}
.subtitle .p-button:hover {
  background-color: var(--hover-colour);
}
.subtitle:hover .p-button {
  transform: scale(1);
}

.card {
  padding: var(--medium);
  border-radius: var(--borderRadius);
  box-shadow: var(--box-shadow-highlight);
  background-image: radial-gradient(50% 500px at right bottom, hsl(var(--colour-background-dark-hsl)/0.25), transparent);
}

.configItemWrapper li + li {
  margin-top: var(--xsmall);
}

.configItem {
  overflow: hidden;
  padding-right: var(--small);
  border: 1px solid hsl(var(--colour-background-dark-hsl));
  border-radius: var(--borderRadius);
  background-color: hsl(var(--colour-background-light-hsl));
}
.configItem .selected {
  height: 100%;
  width: var(--xsmall);
}

[aria-selected=true] .configItem .selected {
  background-color: hsl(var(--colour-feature-hsl));
}

form,
.formFieldsWrapper {
  display: flex;
  flex-direction: column;
  gap: var(--medium);
}

.formField {
  display: flex;
  flex-direction: column;
  row-gap: calc(var(--xsmall) / 2);
}

label {
  font-size: var(--fontSize-small);
  font-weight: 700;
}

.formField:has(:is(input:focus, textarea:focus)) label {
  color: var(--form-highlight);
}

.formField:has(input:is(:read-only, :disabled)):not(:has(.p-hidden-accessible)) label {
  color: hsl(var(--colour-font-lighter-hsl));
}

input:is([type=text], [type=password], [type=email], [type=number], .p-inputtext),
.p-inputtextarea {
  height: calc(var(--medium) * 1.5);
  width: 100%;
  padding: 0 var(--small);
  border: 1px solid hsl(var(--colour-font-lighter-hsl));
  border-radius: var(--borderRadius);
  background-color: hsl(var(--colour-background-hsl));
  font-size: var(--fontSize--standard);
  color: hsl(var(--colour-font-hsl));
  transition: colour 350ms ease, border 350ms ease;
  /* Change the white to any color */
}
input:is([type=text], [type=password], [type=email], [type=number], .p-inputtext):is(:-webkit-autofill, :-webkit-autofill:hover, :-webkit-autofill:focus, :-webkit-autofill:active),
.p-inputtextarea:is(:-webkit-autofill, :-webkit-autofill:hover, :-webkit-autofill:focus, :-webkit-autofill:active) {
  -webkit-box-shadow: 0 0 0 calc(var(--medium) * 1.5) hsl(var(--colour-background-light-hsl)) inset !important;
  -webkit-text-fill-color: hsl(var(--colour-font-hsl)) !important;
}
input:is([type=text], [type=password], [type=email], [type=number], .p-inputtext):hover,
.p-inputtextarea:hover {
  border-color: hsl(var(--colour-font-hsl));
}
input:is([type=text], [type=password], [type=email], [type=number], .p-inputtext):focus,
.p-inputtextarea:focus {
  border-color: var(--form-highlight);
}
input:is([type=text], [type=password], [type=email], [type=number], .p-inputtext).p-error,
.p-inputtextarea.p-error {
  border-color: hsl(var(--colour-error-hsl));
  border-right-width: 16px;
}
input:is([type=text], [type=password], [type=email], [type=number], .p-inputtext):is(:read-only, :disabled),
.p-inputtextarea:is(:read-only, :disabled) {
  cursor: not-allowed;
  background-color: hsl(var(--colour-font-h) var(--colour-font-s) var(--colour-font-l)/0.1);
  color: hsl(var(--colour-font-lighter-hsl));
}

.p-inputtextarea {
  height: auto;
  padding-block: var(--small);
  font-family: var(--font);
}

.p-password.p-error input {
  border-color: hsl(var(--colour-error-hsl));
  border-right-width: 16px;
}

.p-input-icon-right input {
  padding-right: var(--large);
}
.p-input-icon-right i {
  right: var(--small);
}
.p-input-icon-right.p-error i {
  right: var(--medium);
}
.p-input-icon-right.p-password i {
  cursor: pointer;
}

.loadingDialog {
  width: auto;
  background-color: transparent;
  border: none;
  box-shadow: none;
}
.loadingDialog .p-dialog-content {
  background-color: transparent;
  text-align: center;
}
.loadingDialog .message {
  margin-top: 32px;
  font-size: var(--fontSize-large);
  font-weight: bold;
  text-transform: uppercase;
  color: hsl(var(--colour-font-darkest-hsl));
}
.loadingDialog .loadingDot {
  animation-name: loadingDot;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
}
.loadingDialog .loadingDot:nth-child(1) {
  animation-delay: 0.5s;
}
.loadingDialog .loadingDot:nth-child(2) {
  animation-delay: 1s;
}

.p-progress-spinner-circle {
  animation-duration: 1.5s, 3s;
}

@keyframes p-progress-spinner-color {
  100%, 0% {
    stroke: hsl(var(--colour-alt-hsl));
  }
  50% {
    stroke: hsl(var(--colour-feature-hsl));
  }
}
@keyframes loadingDot {
  100%, 0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
.logo {
  height: 100%;
}
.logo .steeper-sUpper {
  fill: hsl(var(--colour-alt-hsl));
}
.logo .steeper-sLower {
  fill: hsl(var(--colour-feature-hsl));
}
.logo .steeper-text {
  fill: hsl(var(--colour-feature-hsl));
}

.navItem {
  display: flex;
  align-items: center;
  gap: var(--xsmall);
}

.orWrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  justify-items: center;
  align-items: center;
}
.orWrapper .orMsg {
  grid-area: 1/1/2/2;
  background-color: hsl(var(--colour-background-hsl));
  color: hsl(var(--colour-font-light-hsl));
}
.orWrapper .orLine {
  grid-area: 1/1/2/2;
  height: 1px;
  border-top: 1px solid hsl(var(--colour-font-lightest-hsl));
}
.orWrapper.horiz {
  width: min(100%, 200px);
  margin-block: var(--medium);
}
.orWrapper.horiz .orMsg {
  padding: 0 var(--xsmall);
}
.orWrapper.horiz .orLine {
  width: 100%;
}
.orWrapper.vert {
  height: 200px;
}
.orWrapper.vert .orMsg {
  padding: var(--xsmall) 0;
}
.orWrapper.vert .orLine {
  height: 100%;
  width: 1px;
  border-left: 1px solid hsl(var(--colour-font-lightest-hsl));
}

.pageHeader .pageTitle {
  position: absolute;
  top: -0.5rem;
  left: -12rem;
  opacity: 0.06;
  font-size: 10rem;
  text-transform: uppercase;
  user-select: none;
  pointer-events: none;
}
.pageHeader .pageTitle-icon {
  font-size: 2rem;
  color: hsl(var(--colour-alt-hsl));
}

[data-theme=dark] .pageTitle {
  opacity: 0.1;
}

.codeWrapper {
  width: min(90%, 360px);
  padding: var(--medium);
  background: #fff;
  border-radius: var(--borderRadius);
  box-shadow: var(--box-shadow);
}

.p-column-filter-menu-button span.pi-filter {
  z-index: 2;
  color: hsl(var(--colour-font-hsl));
}

.p-column-filter-menu-button-open span.pi-filter {
  color: hsl(var(--colour-feature-hsl));
}

.p-column-filter-menu-button-active span.pi-filter {
  color: hsl(var(--colour-background-hsl));
}

.p-column-filter-overlay {
  display: flex;
  flex-direction: column;
  margin-top: 4px;
  background-color: hsla(var(--colour-background-h), var(--colour-background-s), var(--colour-background-l), 0.8);
  border-radius: calc(var(--borderRadius) * 2);
  box-shadow: var(--box-shadow);
  backdrop-filter: blur(10px);
  background-clip: padding-box;
  overflow: hidden;
}

.p-column-filter-operator,
.p-column-filter-constraints,
.p-column-filter-add-rule,
.p-column-filter-buttonbar {
  padding: var(--small);
}

.p-column-filter-operator {
  padding: var(--xsmall var(--small));
  background-color: hsla(var(--colour-font-h), var(--colour-font-s), var(--colour-font-l), 0.1);
  border-bottom: 1px solid hsla(var(--colour-font-h), var(--colour-font-s), var(--colour-font-l), 0.2);
}

.p-column-filter-constraints {
  display: flex;
  flex-direction: column;
  gap: var(--small);
}
.p-column-filter-constraints .p-column-filter-constraint .p-column-filter-matchmode-dropdown {
  margin-bottom: var(--xsmall);
}
.p-column-filter-constraints input + div:not(.p-component) {
  display: flex;
  justify-content: center;
}

.p-column-filter-buttonbar {
  order: -1;
  gap: var(--small);
  border-bottom: 1px solid hsla(var(--colour-font-h), var(--colour-font-s), var(--colour-font-l), 0.2);
}

.p-column-filter-add-rule {
  display: flex;
  justify-content: center;
  padding-top: 0;
}

.p-button.p-column-filter-add-button,
.p-button.p-column-filter-remove-button {
  height: 1.5rem;
  border: none;
  background: transparent;
  font-size: var(--fontSize-small);
  color: hsl(var(--colour-success-hsl));
}

.p-button.p-column-filter-remove-button {
  display: flex;
  margin: var(--small) auto 0;
  color: hsl(var(--colour-error-hsl));
}

.p-column-filter-menu-button.p-column-filter-menu-button-active,
.p-column-filter-menu-button.p-column-filter-menu-button-active:hover {
  color: white;
  overflow: visible;
}
.p-column-filter-menu-button.p-column-filter-menu-button-active::before,
.p-column-filter-menu-button.p-column-filter-menu-button-active:hover::before {
  --oversize: 100%;
  content: "";
  position: absolute;
  top: calc(var(--oversize) * -0.5);
  left: calc(var(--oversize) * -0.5);
  height: calc(100% + var(--oversize));
  aspect-ratio: 1 !important;
  background-color: hsl(var(--colour-feature-hsl));
  opacity: 1;
  transform: scale(1);
  border-radius: 50%;
}

.grid {
  display: grid;
}
.grid.columns-label {
  grid-template-columns: auto 1fr;
}
.grid.columns-label > :nth-child(odd) {
  font-weight: 700;
}
.grid.columns-2 {
  grid-template-columns: 1fr 1fr;
}
.grid.columns-3 {
  grid-template-columns: repeat(3, 1fr);
}
.grid.columns-7 {
  grid-template-columns: repeat(7, 1fr);
}
.grid.columnsAuto-2 {
  grid-template-columns: auto auto;
}
.grid.columnsFreeAuto-2 {
  grid-template-columns: 1fr auto;
}
.grid.columnsAutoFree-2 {
  grid-template-columns: auto 1fr;
}

.firmwareUploadDialog .grid.columns-3 {
  grid-template-columns: 2fr 1fr minmax(15%, auto);
}

.flex, .flexVert {
  display: flex;
}
.flexVert {
  flex-direction: column;
}

.jContent-spaceBetween {
  justify-content: space-between;
}

.jContent-spaceAround {
  justify-content: space-around;
}

.jContent-start {
  justify-content: start;
}

.jContent-center {
  justify-content: center;
}

.jContent-end {
  justify-content: end;
}

.aContent-start {
  align-content: flex-start;
}

.aContent-center {
  align-content: center;
}

.aContent-end {
  align-content: flex-end;
}

.aItems-start {
  align-items: start;
}

.aItems-end {
  align-items: end;
}

.aItems-center {
  align-items: center;
}

.aItems-stretch {
  align-items: stretch;
}

.jSelf-start {
  justify-self: start;
}

.jSelf-center {
  justify-self: center;
}

.jSelf-end {
  justify-self: end;
}

.aSelf-start {
  align-self: start;
}

.aSelf-center {
  align-self: center;
}

.aSelf-end {
  align-self: end;
}

.aSelf-stretch {
  align-self: stretch;
}

* {
  box-sizing: border-box;
}

:focus {
  outline: none;
}

body {
  margin: 0;
}

.appWrapper {
  display: grid;
  grid-template-columns: 1fr;
  min-height: 100vh;
  background-color: hsl(var(--colour-background-hsl));
}
.appWrapper.auth {
  grid-template-areas: "appHeader" "pageHeader" "appContent" "appFooter";
  grid-template-rows: auto auto 1fr auto;
  justify-items: stretch;
}
.appWrapper:is(.guest, .combo) {
  --contentWidth: min(460px, 90%);
  grid-template-areas: "appContent" "appFooter";
  grid-template-rows: 1fr auto;
  align-items: center;
}

.appErrorWrapper {
  --contentWidth: min(460px, 90%);
  grid-template-areas: "appContent" "appFooter";
  grid-template-rows: 1fr auto;
  align-items: center;
}

.appHeader > [class$=-content],
.appMain > [class$=-content],
.appFooter > [class$=-content],
.pageHeader > [class$=-content] {
  position: relative;
  width: var(--contentWidth);
  margin: 0 auto;
  container-type: inline-size;
}

.appHeader {
  grid-area: appHeader;
  height: var(--headerHeight);
}
.appHeader .appHeader-content {
  height: 100%;
}
.appHeader .logo-wrapper {
  height: 40%;
}

.appFooter {
  grid-area: appFooter;
  margin-top: var(--medium);
}

.appMain {
  grid-area: appContent;
}
.appMain .appMain-content {
  display: flex;
  flex-direction: column;
  row-gap: var(--large);
}
.appMain .appMain-content section header {
  position: relative;
  margin-bottom: var(--large);
}
.appMain .appMain-content section header::after {
  --height: 6px;
  content: "";
  position: absolute;
  bottom: calc(var(--height) * -2);
  height: var(--height);
  width: 80px;
  background-color: hsl(var(--colour-alt-hsl));
  border-radius: calc(var(--height) / 2);
}

.pageHeader {
  grid-area: pageHeader;
}

.appName {
  container-type: inline-size;
}
.appName span {
  letter-spacing: 1rem;
}

.hidden {
  display: none;
}

.divider {
  height: 0px;
  width: 50px;
  border-bottom: 1px solid hsl(var(--colour-font-lighter-hsl));
}

.highlightItem {
  align-items: center;
  width: max-content;
}

.block {
  display: block;
}

@media screen and (max-height: 1000px) {
  .appWrapper.guest .appMain-content {
    padding-top: var(--small);
    row-gap: var(--medium);
  }
}
.margin-xsmall {
  margin: var(--xsmall);
}

.marginTop-xsmall {
  margin-top: var(--xsmall);
}

.marginBottom-xsmall {
  margin-bottom: var(--xsmall);
}

.marginLeft-xsmall {
  margin-left: var(--xsmall);
}

.marginRight-xsmall {
  margin-right: var(--xsmall);
}

.margin-small {
  margin: var(--small);
}

.marginTop-small {
  margin-top: var(--small);
}

.marginBottom-small {
  margin-bottom: var(--small);
}

.marginLeft-small {
  margin-left: var(--small);
}

.marginRight-small {
  margin-right: var(--small);
}

.margin-medium {
  margin: var(--medium);
}

.marginTop-medium {
  margin-top: var(--medium);
}

.marginBottom-medium {
  margin-bottom: var(--medium);
}

.marginLeft-medium {
  margin-left: var(--medium);
}

.marginRight-medium {
  margin-right: var(--medium);
}

.margin-large {
  margin: var(--large);
}

.marginTop-large {
  margin-top: var(--large);
}

.marginBottom-large {
  margin-bottom: var(--large);
}

.marginLeft-large {
  margin-left: var(--large);
}

.marginRight-large {
  margin-right: var(--large);
}

.margin-xlarge {
  margin: var(--xlarge);
}

.marginTop-xlarge {
  margin-top: var(--xlarge);
}

.marginBottom-xlarge {
  margin-bottom: var(--xlarge);
}

.marginLeft-xlarge {
  margin-left: var(--xlarge);
}

.marginRight-xlarge {
  margin-right: var(--xlarge);
}

.padding-xsmall {
  padding: var(--xsmall);
}

.paddingTop-xsmall {
  padding-top: var(--xsmall);
}

.paddingBottom-xsmall {
  padding-bottom: var(--xsmall);
}

.paddingLeft-xsmall {
  padding-left: var(--xsmall);
}

.paddingRight-xsmall {
  padding-right: var(--xsmall);
}

.padding-small {
  padding: var(--small);
}

.paddingTop-small {
  padding-top: var(--small);
}

.paddingBottom-small {
  padding-bottom: var(--small);
}

.paddingLeft-small {
  padding-left: var(--small);
}

.paddingRight-small {
  padding-right: var(--small);
}

.padding-medium {
  padding: var(--medium);
}

.paddingTop-medium {
  padding-top: var(--medium);
}

.paddingBottom-medium {
  padding-bottom: var(--medium);
}

.paddingLeft-medium {
  padding-left: var(--medium);
}

.paddingRight-medium {
  padding-right: var(--medium);
}

.padding-large {
  padding: var(--large);
}

.paddingTop-large {
  padding-top: var(--large);
}

.paddingBottom-large {
  padding-bottom: var(--large);
}

.paddingLeft-large {
  padding-left: var(--large);
}

.paddingRight-large {
  padding-right: var(--large);
}

.padding-xlarge {
  padding: var(--xlarge);
}

.paddingTop-xlarge {
  padding-top: var(--xlarge);
}

.paddingBottom-xlarge {
  padding-bottom: var(--xlarge);
}

.paddingLeft-xlarge {
  padding-left: var(--xlarge);
}

.paddingRight-xlarge {
  padding-right: var(--xlarge);
}

.gap-xsmall {
  gap: var(--xsmall);
}

.gapRow-xsmall {
  row-gap: var(--xsmall);
}

.gapCol-xsmall {
  column-gap: var(--xsmall);
}

.gap-small {
  gap: var(--small);
}

.gapRow-small {
  row-gap: var(--small);
}

.gapCol-small {
  column-gap: var(--small);
}

.gap-medium {
  gap: var(--medium);
}

.gapRow-medium {
  row-gap: var(--medium);
}

.gapCol-medium {
  column-gap: var(--medium);
}

.gap-large {
  gap: var(--large);
}

.gapRow-large {
  row-gap: var(--large);
}

.gapCol-large {
  column-gap: var(--large);
}

.gap-xlarge {
  gap: var(--xlarge);
}

.gapRow-xlarge {
  row-gap: var(--xlarge);
}

.gapCol-xlarge {
  column-gap: var(--xlarge);
}

body,
.appWrapper {
  font-family: var(--fontFamily);
  color: hsl(var(--colour-font-hsl));
}

h1,
h2,
h3,
h4,
h5,
p,
pre {
  margin: 0;
  line-height: 1.1;
}

h1 {
  font-size: 6rem;
  line-height: 0.9;
}

h2 {
  font-size: clamp(2rem, 4.5cqw, 4rem);
}

p {
  line-height: 1.4;
}
p + p {
  margin-top: var(--small);
}

a,
.link,
.p-link {
  cursor: pointer;
  color: hsl(var(--colour-feature-hsl));
  text-decoration: none;
  transition: color 350ms ease;
}
a:not(.p-disabled, :disabled):hover,
.link:not(.p-disabled, :disabled):hover,
.p-link:not(.p-disabled, :disabled):hover {
  color: hsl(var(--colour-alt-hsl));
}
a:is(.p-disabled, :disabled),
.link:is(.p-disabled, :disabled),
.p-link:is(.p-disabled, :disabled) {
  color: hsl(var(--colour-feature-lighter-hsl));
}

::placeholder {
  color: hsl(var(--colour-font-lighter-hsl));
}

.font-small {
  font-size: var(--fontSize-small);
}
.font-large {
  font-size: var(--fontSize-large);
}
.font-xlarge {
  font-size: var(--fontSize-xlarge);
}
.font-bold {
  font-weight: 700;
}
.font-light {
  color: hsl(var(--colour-font-light-hsl));
}
.font-lighter {
  color: hsl(var(--colour-font-lighter-hsl));
}
.font-lightest {
  color: hsl(var(--colour-font-lightest-hsl));
}
.font-dark {
  color: hsl(var(--colour-font-dark-hsl));
}
.font-darker {
  color: hsl(var(--colour-font-darker-hsl));
}
.font-darkest {
  color: hsl(var(--colour-font-darkest-hsl));
}
.font-error {
  color: hsl(var(--colour-error-hsl));
}
.font-success {
  color: hsl(var(--colour-success-hsl));
}
.font-info {
  color: hsl(var(--colour-info-hsl));
}
.font-warn {
  color: hsl(var(--colour-warn-hsl));
}
.font__colour--white {
  color: white;
}
.font__colour--feature {
  color: hsl(var(--colour-feature-hsl));
}
.font__colour--alt {
  color: hsl(var(--colour-alt-hsl));
}

.text-lineHeight-1 {
  line-height: 1;
}
.text-center {
  text-align: center;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-capitalize {
  text-transform: capitalize;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-copy {
  cursor: pointer;
  position: relative;
  display: flex;
  gap: var(--medium);
  padding: var(--xsmall);
  background-color: hsl(var(--colour-alt-lightest-hsl));
  border: 1px solid hsl(var(--colour-alt-lighter-hsl));
}
.text-copy span {
  width: max-content;
}
.text-vertical, .text-verticalAlt {
  line-height: 1;
  writing-mode: vertical-lr;
}
.text-verticalAlt {
  rotate: 180deg;
}

.label {
  font-weight: 700;
}
